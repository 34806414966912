@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
  font-family: "Sora", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "mudclaw";
  src: local("Mudclaw"),
    url("../src/assets/Mudclaw/MUDCLAW.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "mabry";
  src: local("Mabry"),
    url("../src/assets/MabryPro-Regular/MabryPro-Regular.ttf")
      format("truetype");
  font-weight: 400;
}
