.main_container {
  position: relative;
}

.main_container::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  background: white no-repeat;
  height: 100vh;
  width: 100vw;
  z-index: -2;
}

.navbar_container {
  display: flex;
  justify-content: space-between;
  height: 10vh;
  border-bottom: 1px solid #111;
  border-top: 1px solid #111;
  position: relative;
}

.navbar_container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: -2;
}

.navbar_container > img {
  width: auto;
  background-color: #b86cff;
}

.navbar_list_container > ul {
  display: flex;
  text-decoration: none;
  list-style: none;
  align-items: center;
  height: 100%;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;
}

.navbar_list_container > ul > li {
  display: flex;
  padding-left: 3em;
  padding-right: 3em;
  border-left: 1px solid;
  height: 100%;
}

.navbar_list_container > ul > li > a {
  text-decoration: none;
  align-self: center;
  color: black;
  transition: all 300ms ease;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 90%;
}

.container > img {
  position: absolute;
  z-index: -1;
  right: 0;
  transform: translateX(15%);
  bottom: 0;
  width: auto;
  /* height: 70px; */
}

.content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 80%;
  margin-left: 3.5%;
}

.content_container button {
  width: fit-content;
  padding: 1em 2em;
  box-shadow: 4px 4px 0px #111;
  border-radius: 0;
  background-color: #d9d9d9;
  font-weight: 800;
  outline: 2px solid black;
  border: 0;
  transition: all 400ms ease;
  display: flex;
  justify-content: center;
}

.content_container button:hover {
  background-color: black;
  color: white;
  box-shadow: 4px 4px 0px #b86cff;
}

.title_heading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.title_heading_container > h1 {
  font-size: 4em;
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-family: "mudclaw";
  color: #b86cff;
}

.title_heading_container > h3 {
  /* font-size: 8em; */
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-family: "mudclaw";
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "mudclaw";
  color: #b86cff;
}

#event-loc {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "mudclaw";
}

.title_heading_container > p {
  text-align: center;
  width: 70%;
}

.Watch_now {
  text-align: center;
  background-color: #b86cff;
}

@media only screen and (max-width: 950px) {
  .main_container::before {
    backdrop-filter: blur(20px);
  }
  .container > img {
    filter: blur(2px);
  }
  .container {
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .container > img {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .navbar_list_container > ul > li {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media only screen and (max-width: 500px) {
  .navbar_container > img {
    display: none;
  }
  .navbar_container {
    display: flex;
    justify-content: space-around;
    height: 10vh;
    border-bottom: 1px solid #111;
    border-top: 1px solid #111;
    position: relative;
  }

  .navbar_list_container > ul > li {
    display: flex;

    border-left: 0px solid;
    border-right: 1px solid;
  }

  .Watch_now {
    text-align: center;
    background-color: white;
    border-right: 0px black !important;
  }
}
@media only screen and (max-width: 400px) {
  .title_heading_container > h1 {
    font-size: 2.5em;
  }
  .title_heading_container > p {
    text-align: center;
    width: 99%;
  }
}

@media only screen and (max-width: 350px) {
  .navbar_list_container > ul > li > a {
    font-size: 16px;
  }
}
